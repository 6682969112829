<template>
  <v-container fluid class="grey lighten-4" style="height: 100%">
    <v-row justify="center">
      <v-col>
        <h1>Podsumowanie finansów</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="payments"
          :sort-by="['p_number', 'value', 'isPayed']"
          :sort-desc="[true, true]"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:[`item.invoice`]="{ item }">
            <div v-if="item.invoice == 'brak'">{{ item.invoice }}</div>
            <div v-if="item.invoice != 'brak'">
              <a :href="item.invoice.invoice_url" target="_blank">{{
                item.invoice.invoice_number
              }}</a>
            </div>
          </template>
          <template v-slot:[`item.transports`]="{ item }">
            <div v-if="transports[item.payment_id]">
              <div v-for="transport of transports[item.payment_id]" :key="transport.id">
                <router-link :to="'/transporty/' + transport.id">{{
                  transport.number
                }}</router-link>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    data: () => ({
      tableHeaders: [
        {
          text: 'Numer płatności',
          align: 'left',
          value: 'p_number',
        },
        {
          text: 'Transporty',
          value: 'transports',
        },
        {
          text: 'Data dodania',
          value: 'date_created',
        },
        {
          text: 'Cena netto',
          value: 'price_net',
        },
        {
          text: 'Cena brutto',
          value: 'price_gross',
        },
        {
          text: 'Faktura',
          value: 'invoice',
        },
      ],
      payments: [],
      transports: {},
      summaryNet: 0,
      summaryGross: 0,
      ordersNet: 0,
      search: null,
    }),
    methods: {
      getPayments: function () {
        if (this.$route.params.id) {
          axios({
            url: this.$store.state.url + '/api/payment/get',
            data: {
              token: this.$store.state.token,
              params: {
                user_id: this.$route.params.id,
              },
            },
            method: 'POST',
          }).then(resp => {
            this.payments = resp.data.payments;

            this.payments.forEach(payment => {
              payment.invoice = payment.invoice.length ? payment.invoice[0] : 'brak';
              this.getTransports(payment.payment_id);
            });
          });
        } else {
          axios({
            url: this.$store.state.url + '/api/payment/get',
            data: {
              token: this.$store.state.token,
            },
            method: 'POST',
          }).then(resp => {
            this.payments = resp.data.payments;

            this.payments.forEach(payment => {
              payment.invoice = payment.invoice.length ? payment.invoice[0] : 'brak';
              this.getTransports(payment.payment_id);
            });
          });
        }
      },
      getTransports: function (id) {
        axios({
          url: this.$store.state.url + '/api/transport/get',
          data: {
            token: this.$store.state.token,
            type: 'all',
            params: {
              payment_id: id,
            },
          },
          method: 'POST',
        }).then(resp => {
          resp.data.transports.forEach(t => {
            this.transports[id] = this.transports[id] ? this.transports[id] : [];

            this.transports[id].push({
              id: t.transport_id,
              number: t.t_number,
            });
          });

          this.payments.push([]);
          this.payments.pop();
        });
      },
    },
    created() {
      this.getPayments();
    },
  };
</script>
